<div class="tess-avatar-outside">
  @if (loaded() && !src()) {
    <tess-ghost-element [class]="['tess-avatar', 'tess-avatar-' + size]" [title]="title()"> {{ initials() }}</tess-ghost-element>
  }

  <img
    [hidden]="!(loaded() && src())"
    [src]="src()"
    [title]="title()"
    [alt]="title()"
    [class]="['tess-avatar', 'tess-avatar-' + size()]"
    (error)="onError()"
    (load)="onLoaded()"
    [@fadeIn] />

  @if (!loaded()) {
    <div [class]="['tess-avatar', 'ghost-element', 'tess-avatar', 'tess-avatar-' + size()]"></div>
  }
</div>
