import { ChangeDetectionStrategy, Component, HostBinding, input, OnInit, signal } from '@angular/core';
import { trigger } from '@angular/animations';

import { fadeIn } from '@core/animations/fade-animations';

import { GhostElementComponent } from '@core/ghost-element/ghost-element.component';

@Component({
  standalone: true,
  selector: 'tess-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('fadeIn', fadeIn(500))],
  imports: [GhostElementComponent],
})
export class AvatarComponent implements OnInit {
  readonly src = input<string>();
  readonly initials = input<string>();
  readonly title = input<string>();
  readonly size = input.required<string>(); // 'xxs | xs | sm | md | md2 | lg | xl';

  @HostBinding('style.--fontSize')
  fontSize: string;

  @HostBinding('style.--insideWidth')
  insideWidth: string;

  @HostBinding('style.--outsideWidth')
  outsideWidth: string;

  @HostBinding('style.--borderShadowColor')
  borderShadowColor: string;

  protected loaded = signal(false);

  ngOnInit(): void {
    switch (this.size()) {
      case 'xxs':
        this.fontSize = '10px';
        this.insideWidth = '20px';
        this.outsideWidth = '20px';
        break;
      case 'xs':
        this.fontSize = '10px';
        this.insideWidth = '22px';
        this.outsideWidth = '26px';
        break;
      case 'sm':
        this.fontSize = '13px';
        this.insideWidth = '28px';
        this.outsideWidth = '32px';
        break;
      case 'md':
        this.fontSize = '13px';
        this.insideWidth = '32px';
        this.outsideWidth = '36px';
        break;
      case 'md2':
        this.fontSize = '16px';
        this.insideWidth = '42px';
        this.outsideWidth = '46px';
        break;
      case 'lg':
        this.fontSize = '24px';
        this.insideWidth = '64px';
        this.outsideWidth = '68px';
        break;
      case 'xl':
        this.fontSize = '27px';
        this.insideWidth = '78px';
        this.outsideWidth = '82px';
        break;
    }
  }

  onError() {
    if (this.title() && this.initials()) {
      this.loaded.set(true);
    }
  }

  onLoaded() {
    this.loaded.set(true);
  }
}
